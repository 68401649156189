<template>
  <div class="statistics">
    <div class="title">
      Les statistiques du planning opérationnel
    </div>
    <hr />
    <div v-if="getReportingErrorStatic" class="error">
      <ul>
        <li v-for="(error, index) in getReportingErrorStatic" :key="index">
          {{ error }}
        </li>
      </ul>
    </div>
    <div
      v-if="getReportingProcessingStatic"
      class="stat-container three-dots-loading"
    >
      Chargement en cours
    </div>

    <!-- <div
      class="stat-container"
      v-if="!getReportingProcessingStatic && getReportingStats && selectedWeek"
    >
      <div class="head-stat">
        <div class="main-stat">
          <div class="value">
            {{ getReportingStats.total_planifie }}
          </div>
          <div class="unit">m²</div>
        </div>
        <div class="sub-stat">
          {{ getReportingStats.nbr_planifie }} Poses planifiées
        </div>
      </div>
      <div class="head-stat">
        <div class="main-stat">
          <div class="value">{{ getReportingStats.total_pose }}</div>
          <div class="unit">m²</div>
        </div>
        <div class="sub-stat">
          {{ getReportingStats.nbr_pose }} Poses terminées
        </div>
      </div>
      <div class="head-stat">
        <div class="main-stat">
          <div class="value">
            {{ Math.round(getReportingStats.percentage_pose_planifie) }}%
          </div>
          <div class="unit"></div>
        </div>
        <div class="sub-stat">P/P</div>
      </div>
    </div> -->
    <div class="grid-container-statistics">
      <table class="grid" v-if="selectedWeek">
        <colgroup>
          <col width="2px" />
          <col width="8px" />
          <col width="5px" />
          <col width="5px" />
          <col width="5px" />
          <col width="5px" />
          <col width="5px" />
          <col width="5px" />
          <col width="5px" />
          <col width="5px" />
          <col width="5px" />
          <col width="5px" />
          <col width="5px" />
          <col width="5px" />
          <col width="5px" />
          <col width="5px" />
          <col width="5px" />
          <col width="5px" />
          <col width="5px" />
          <col width="5px" />
          <col width="5px" />
          <col width="5px" />
          <col width="5px" />
        </colgroup>
        <tr>
          <th colspan="3" class="blue">Groupe</th>
          <th colspan="2" class="blue">Planifié</th>
          <th colspan="9" class="blue">Posé</th>
          <th colspan="3" class="blue">Camions</th>
          <th colspan="2" class="blue">WA régie</th>
          <th colspan="2" class="blue">WA Lead</th>
          <th colspan="2" class="blue">WA Terrain</th>
        </tr>
        <tr>
          <th colspan="1" class="grey-light"></th>
          <th colspan="1" class="grey-light">Filiales</th>
          <th colspan="1" class="grey-light">Dépôt</th>
          <th colspan="1" class="grey-light">Pose</th>
          <th colspan="1" class="grey-light">m2</th>
          <th colspan="1" class="grey-light">Pose</th>
          <th colspan="1" class="grey-light">m2</th>
          <th colspan="1" class="grey-light">P/P</th>
          <th colspan="1" class="grey-light">101</th>
          <th colspan="1" class="grey-light">102</th>
          <th colspan="1" class="grey-light">103</th>
          <th colspan="1" class="grey-light">P</th>
          <th colspan="1" class="grey-light">CLS</th>
          <th colspan="1" class="grey-light">CA</th>
          <th colspan="1" class="grey-light">Activé</th>
          <th colspan="1" class="grey-light">m2</th>
          <th colspan="1" class="grey-light">CA</th>
          <th colspan="1" class="grey-light">Taux</th>
          <th colspan="1" class="grey-light">CA</th>
          <th colspan="1" class="grey-light">Taux</th>
          <th colspan="1" class="grey-light">CA</th>
          <th colspan="1" class="grey-light">Taux</th>
          <th colspan="1" class="grey-light">CA</th>
        </tr>
        <template v-for="(filiale, indexf) in getReportingStatFiliales">
          <tr
            class="selectedRow"
            :key="'a' + indexf + indexd"
            v-for="(depot, indexd) in filiale.deposits"
            :class="{
              'grey-row': !allOpen && !filiale.checked
            }"
          >
            <th :rowspan="filiale.deposits.length" v-if="indexd == 0">
              <b-form-checkbox
                @change="selected(filiale)"
                v-model="filiale.checked"
              >
              </b-form-checkbox>
            </th>
            <th :rowspan="filiale.deposits.length" v-if="indexd == 0">
              {{ filiale.name }}
            </th>
            <th :class="{ blue: depot.class, 'grey-light': !depot.class }">
              {{ depot.name }}
            </th>
            <td
              :class="{
                'grey-light': depot.class
              }"
            >
              {{ depot.totals.pose_planifie_w }}
            </td>
            <td
              :class="{
                'grey-light': depot.class
              }"
            >
              {{ depot.totals.m_planife_w }}
            </td>
            <td
              :class="{
                'grey-light': depot.class
              }"
            >
              {{ depot.totals.pose_realise_w }}
            </td>
            <td
              :class="{
                'grey-light': depot.class
              }"
            >
              {{ depot.totals.m_realise_w }}
            </td>
            <td
              :class="{
                'grey-light': depot.class
              }"
            >
              {{ depot.totals.planif_pose_percent_w }}
            </td>
            <td
              :class="{
                'grey-light': depot.class
              }"
            >
              {{ depot.totals.t_101_w }}
            </td>
            <td
              :class="{
                'grey-light': depot.class
              }"
            >
              {{ depot.totals.t_102_w }}
            </td>
            <td
              :class="{
                'grey-light': depot.class
              }"
            >
              {{ depot.totals.t_103_w }}
            </td>
            <td
              :class="{
                'grey-light': depot.class
              }"
            >
              {{ depot.totals.p_value_w }} %
            </td>
            <td
              :class="{
                'grey-light': depot.class
              }"
            >
              {{ depot.totals.cls_value_w }} %
            </td>
            <td
              :class="{
                'grey-light': depot.class
              }"
            >
              {{ depot.totals.ca_value_w }} €
            </td>
            <td
              :class="{
                'grey-light': depot.class
              }"
            >
              {{ depot.totals.camion_active_w }}
            </td>
            <td
              :class="{
                'grey-light': depot.class
              }"
            >
              {{ depot.totals.camion_ca_w }} €
            </td>
            <td
              :class="{
                'grey-light': depot.class
              }"
            >
              {{ depot.totals.camion_m_w }}
            </td>
            <td
              :class="{
                'grey-light': depot.class
              }"
            >
              {{ depot.totals.wa_regie_taux_w }} %
            </td>
            <td
              :class="{
                'grey-light': depot.class
              }"
            >
              {{ depot.totals.wa_regie_ca_w }} €
            </td>
            <td
              :class="{
                'grey-light': depot.class
              }"
            >
              {{ depot.totals.wa_lead_taux_w }} %
            </td>
            <td
              :class="{
                'grey-light': depot.class
              }"
            >
              {{ depot.totals.wa_lead_ca_w }} €
            </td>
            <td
              :class="{
                'grey-light': depot.class
              }"
            >
              {{ depot.totals.wa_terrain_taux_w }} %
            </td>
            <td
              :class="{
                'grey-light': depot.class
              }"
            >
              {{ depot.totals.wa_terrain_ca_w }} €
            </td>
          </tr>
        </template>
      </table>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  props: {
    selectedWeek: {
      required: true
    }
  },
  data() {
    return {
      selectedRow: [],
      selectedCheck: false
    }
  },
  methods: {
    ...mapActions([
      'fetchStatistics',
      'fetchVisiblesDeposits',
      'fetchStatisticsCheck'
    ]),
    selected(filiale) {
      filiale.checked = !filiale.checked
      this.fetchStatisticsCheck()
    }
  },
  computed: {
    ...mapGetters([
      'getReportingStats',
      'getReportingErrorStatic',
      'getReportingProcessingStatic',
      'getReportingStatFiliales'
    ]),
    allOpen: function() {
      let result = true
      this.getReportingStatFiliales.forEach(f => {
        if (f.checked) {
          result = false
        }
      })
      return result
    }
  },
  async mounted() {
    await this.fetchVisiblesDeposits()
    this.fetchStatisticsCheck()
    if (this.selectedWeek) {
      this.fetchStatistics(this.selectedWeek)
    }
  },
  watch: {
    selectedWeek: function(week) {
      if (week) {
        this.fetchStatistics(this.selectedWeek)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.statistics {
  height: 100%;
  .title {
    font-size: 26px;
  }
  .error {
    color: #ec2f1c;
    text-overflow: ellipsis;
    overflow: hidden;
    line-height: 1rem;
    margin-top: 5px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .stat-container {
    display: flex;
    justify-content: center;
    padding-bottom: 22px;
    .head-stat {
      text-align: center;
      font-weight: 700;
      margin: 0 15px;
      color: #383e42;
      .main-stat {
        display: flex;
        justify-content: center;
        position: relative;
        white-space: nowrap;
        .value {
          font-size: 35px;
        }
        .unit {
          font-size: 14px;
          display: block;
          position: relative;
          margin-top: -5px;
        }
      }
      .sub-stat {
        font-size: 14px;
        margin-top: 6px;
        white-space: nowrap;
        font-style: italic;
      }
    }
  }
  .grid-container-statistics {
    display: block;
    position: relative;
    overflow: auto;
    margin-right: 2px;
    height: calc(100% - 45px);
    table {
      &.grid {
        width: 1875px;
        border-collapse: collapse;
        table-layout: fixed;
        box-sizing: border-box;
        empty-cells: show;
        outline: 0;
        tr {
          height: 30px;
          &.tr-1 {
            height: 38px;
          }
          &.tr-2 {
            height: 22px;
          }
          th,
          td {
            position: relative;
            text-align: center;
            border: 1px solid #ced4da;
            outline: 0;
            vertical-align: middle;
            overflow: hidden;
            font-weight: 400;
            text-overflow: ellipsis;
            font-size: 11px;
            white-space: nowrap;
            box-sizing: border-box;
            padding: 0 7px;
            &.blue-white {
              background-color: #2dabe2;
              color: #fff;
              font-weight: bold;
            }
            &.blue {
              background-color: #2dabe2;
              color: #fff;
            }
            &.yellow {
              background-color: #ffe436;
            }

            &.grey {
              background-color: #dbdbdb;
            }
            &.grey-light {
              background-color: #ededed;
              font-weight: bold;
            }
            &.s-12 {
              font-size: 12px;
            }

            &.s-14 {
              font-size: 14px;
            }
            &.t-up {
              text-transform: uppercase;
            }

            &.sticky {
              position: sticky;
              left: 0;
              z-index: 1;
            }
            &.offset {
              left: 100px;
            }
          }
          th {
            font-weight: 600;
          }
        }
      }

      tr {
        &.grey-row {
          th,
          td {
            background-color: #a6a6a6 !important;
            opacity: 0.4;
          }
        }
      }
    }
  }
}
</style>
